import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import ClearingService from "./components/clearingService";
import HouseCleaner from "./components/houseCleaner";
import Pruner from "./components/pruner";
import ScrapMerchant from "./components/scrapMerchant";
import Home from "./components/home";
import Contact from "./components/contact";
import LegalNotice from "./components/legalNotice";
import PrivacyPolicy from "./components/privacyPolicy";
import ScrollToTop from "./components/scrollToTop";
import ReactGA from "react-ga";

import TagManager from "react-gtm-module";
const TRACKING_ID = "G-D6NB8HC4HB";
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: "G-D6NB8HC4HB",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact="true" element={<Home />}></Route>
        <Route
          path="/debarras"
          exact="true"
          element={<ClearingService />}
        ></Route>
        <Route path="/contact" exact="true" element={<Contact />}></Route>
        <Route path="/elagage" exact="true" element={<Pruner />}></Route>
        <Route
          path="/ferraille"
          exact="true"
          element={<ScrapMerchant />}
        ></Route>
        <Route
          path="/videmaison"
          exact="true"
          element={<HouseCleaner />}
        ></Route>
        <Route
          path="/mentions-legales"
          exact="true"
          element={<LegalNotice />}
        ></Route>
        <Route
          path="/privacy-policy"
          exact="true"
          element={<PrivacyPolicy />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
