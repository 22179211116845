import NavBar from "./header";
import Footer from "./footer";
import "../styles/scrapmerchant.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { ReactComponent as Phone } from "../assets/images/svg/phone.svg";
import { ReactComponent as Mail } from "../assets/images/svg/mail.svg";
import { ReactComponent as Form } from "../assets/images/svg/form.svg";
import { ReactComponent as Recycling } from "../assets/images/svg/recycling.svg";
import { ReactComponent as Moneybag } from "../assets/images/svg/moneybag.svg";
import { ReactComponent as Time } from "../assets/images/svg/time.svg";
import { ReactComponent as Knowledge } from "../assets/images/svg/knowledge.svg";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/images/scrapMerchant", true, /\.(png|jpe?g|svg)$/)
);

const ScrapMerchant = () => {
  return (
    <div>
      <NavBar />

      <div className="scrapmerchant-banner-title">
        <img
          id="banner"
          src={require("../assets/images/header/banner_scrapmerchant.png")}
          alt="banner_scrapmerchant"
          className="banner"
        ></img>
        <div className="home-site-title">
          <h1 id="title">Récupération fer et métaux</h1>
        </div>
      </div>
      <div className="firstblock">
        <h2 className="scrapmerchant-h2">En quoi consiste notre métier ?</h2>
        <div className="blocks">
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce="true"
          >
            <div className="left-block">
              <div className="left-block-text">
                <p>
                  Ferrailleur depuis <b>plus de 20 ans</b>, notre entreprise
                  dispose d'une expérience sans pareille dans ce domaine. Nous
                  récupérons <b>toutes sortes de ferrailles</b> pour ensuite les
                  trier afin de les faire recycler dans{" "}
                  <b>le respect des normes</b> en centre agrégé.
                </p>
                <p></p>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInRightBig"
            animateOnce="true"
          >
            <div className="right-block">
              <img
                className="illustrations"
                src={images["ferraille.jpg"]}
                alt="feraille"
              />
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="scrapmerchant-services">
        <h2 className="scrapmerchant-h2">
          Dans quel cadre faire appel à nous ?
        </h2>
        <div className="blocks">
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce="true"
          >
            <div className="left-block">
              <img
                className="illustrations"
                src={images["ferraille2.jpg"]}
                alt="feraille"
              />
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInRightBig"
            animateOnce="true"
          >
            <div className="right-block">
              <div className="left-block-text">
                <ul>
                  <li>
                    Une épave à faire enlever. <span className="check">✓</span>
                  </li>
                  <li>
                    Une cuve à dégazer. <span className="check">✓</span>
                  </li>
                  <li>
                    Un tas de ferrailles ou de métaux à faire enlever.{" "}
                    <span className="check">✓</span>
                  </li>
                  <li>
                    Travaux de découpe pour débarrassage de ferrailles.{" "}
                    <span className="check">✓</span>
                  </li>
                  <li>
                    Travaux de démontage pour débarrassage.{" "}
                    <span className="check">✓</span>
                  </li>
                </ul>
              </div>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="thirdblock">
        <h2 className="scrapmerchant-h2">Nos points forts :</h2>
        <div className="scrapmerchant-cards">
          <div className="scrapmerchant-card">
            <div className="scrapmerchant-card-content">
              <div className="scrapmerchant-card-title">Expérience</div>
              <Knowledge />
              <p className="scrapmerchant-card-description">
                Dans le traitement de la ferraille depuis ...
              </p>
            </div>
          </div>
          <div className="scrapmerchant-card">
            <div className="scrapmerchant-card-content">
              <div className="scrapmerchant-card-title">Gratuité</div>
              <Moneybag />
              <p className="scrapmerchant-card-description">
                Déplacement et enlèvement <u>gratuit</u>.
              </p>
            </div>
          </div>
          <div className="scrapmerchant-card">
            <div className="scrapmerchant-card-content">
              <div className="scrapmerchant-card-title">
                Respect de l'environnement
              </div>
              <Recycling />
              <p className="scrapmerchant-card-description">
                Traitement et recyclage des métaux/ferrailles en centre
                professionnel.
              </p>
            </div>
          </div>
          <div className="scrapmerchant-card">
            <div className="scrapmerchant-card-content">
              <div className="scrapmerchant-card-title">Disponibilité</div>
              <Time />
              <p className="scrapmerchant-card-description">
                Nous intervenons rapidement et efficacement, vous pouvez nous
                contacter 24h/24 et 7j/7.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="scrapmerchant-gallery">
        <h2 className="scrapmerchant-h2">Galerie des réalisations</h2>
        <div className="gallery">
          <div className="gallery__column">
            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 1.jpg"]}
                  alt=""
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Location d'une nacelle pour démontage d'un silo.
                </figcaption>
              </figure>
            </span>

            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 5.jpg"]}
                  alt=""
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>

            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 9.jpg"]}
                  alt=""
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>
          </div>

          <div className="gallery__column">
            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 2.jpg"]}
                  alt=""
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>

            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 6.jpg"]}
                  alt=""
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>

            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 10.jpg"]}
                  alt="empty"
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>
          </div>

          <div className="gallery__column">
            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 3.jpg"]}
                  alt="empty"
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>

            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 7.jpg"]}
                  alt="empty"
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>

            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["na.jpg"]}
                  alt="empty"
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>
          </div>

          <div className="gallery__column">
            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 4.jpg"]}
                  alt="empty"
                  className="gallery__image"
                ></img>
                <figcaption className="gallery__caption">
                  Démontage d'un silo.
                </figcaption>
              </figure>
            </span>

            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["Silo 8.jpg"]}
                  alt="empty"
                  className="gallery__image"
                ></img>
              </figure>
            </span>

            <span target="_blank" className="gallery__link">
              <figure className="gallery__thumb">
                <img
                  src={images["na.jpg"]}
                  alt="empty"
                  className="gallery__image"
                ></img>
              </figure>
            </span>
          </div>
        </div>
      </div>

      <div className="fourthblock">
        <div className="scrapmerchant-contact">
          <h2 className="scrapmerchant-h2">Nous contacter</h2>
          <div className="contact">
            <div className="contact-widgets">
              <div>
                <Phone />
              </div>
              <div>
                par téléphone au <a href="tel:06 24 86 62 78">06 24 86 62 78</a>
                .
              </div>
            </div>
            <div className="contact-widgets">
              <div>
                <Mail />
              </div>
              <div>
                par email à l'adresse{" "}
                <a href="mailto:ent.heldebaume@gmail.com">
                  ent.heldebaume@gmail.com
                </a>
                .
              </div>
            </div>
            <div className="contact-widgets">
              <div>
                <Form />
              </div>
              <div>
                ou directement depuis le formulaire de contact{" "}
                <a href="/contact">ici</a>.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ScrapMerchant;
