import React, { useState } from "react";
import NavBar from "./header";
import "../styles/contact.css";
import emailjs from "emailjs-com";
import { useRef } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Footer from "./footer";

import { ReactComponent as Phone } from "../assets/images/svg/phone.svg";
import { ReactComponent as Mail } from "../assets/images/svg/mail.svg";
import { ReactComponent as Form } from "../assets/images/svg/form.svg";

const Contact = () => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const form = useRef();

  const [fields, setFields] = useState({});

  function clear() {
    const inputs = document.querySelectorAll(
      "#firstname, #lastname, #email, #phonenumber, #message, #area, #accuracy, #premises"
    );
    const subjectInput = document.querySelector("#subject");

    subjectInput.value = "instructions";
    inputs.forEach((input) => {
      input.value = "";
    });
    setFields({ ...fields, subject: "instructions" });
  }

  function handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFields({ ...fields, [name]: value });
  }

  const sendEmail = (e) => {
    e.preventDefault();

    var template = "";
    const actualSubject = document.querySelector("#subject");
    if (actualSubject.value === "Vide-maison") {
      template = "template_haymalj";
    } else {
      template = "template_0ignl0e";
    }

    emailjs
      .sendForm(
        "service_0493wqu",

        template,
        form.current,
        "RjtjBlQdQNekr39mG"
      )
      .then(
        (result) => {
          setOpen((o) => !o);
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <NavBar />
      <div className="contact-banner-title">
        <img
          id="banner"
          src={require("../assets/images/header/banner_contact.png")}
          alt="banner_contact"
          className="banner"
        ></img>
        <div className="home-site-title">
          <h1 className="black-title">Contacter l'entreprise</h1>
        </div>
      </div>

      <div>
        <div className="container">
          <form className="myform" ref={form} onSubmit={sendEmail}>
            <div className="row">
              <div className="col-25">
                <label htmlFor="fname">
                  Prénom <span className="required">*</span>
                </label>
              </div>
              <div className="col-75">
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder="Prénom..."
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="lname">
                  Nom <span className="required">*</span>
                </label>
              </div>
              <div className="col-75">
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="Nom de famille..."
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="email">Email</label>
              </div>
              <div className="col-75">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email..."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="phonenumber">
                  Téléphone <span className="required">*</span>
                </label>
              </div>
              <div className="col-75">
                <input
                  type="text"
                  id="phonenumber"
                  name="phonenumber"
                  placeholder="Téléphone..."
                  pattern="(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="subject">
                  Sujet <span className="required">*</span>
                </label>
              </div>
              <div className="col-75">
                <select
                  id="subject"
                  name="subject"
                  onChange={handleChange}
                  value={fields["subject"]}
                  defaultValue="instructions"
                  required
                >
                  <option disabled value="instructions">
                    -- Choisir un sérvice --
                  </option>

                  <option value="Ferraille">Ferraille</option>
                  <option value="Sérvices">Services en tout genre</option>
                  <option value="Élagage">Élagage</option>
                  <option value="Vide-maison">Vide-maison</option>
                  <option value="Autre demande">Autre demande</option>
                </select>
                {fields["subject"] === "Vide-maison" ? (
                  <>
                    <div className="col-25">
                      <label htmlFor="premises">
                        Type de local <span className="required">*</span>
                      </label>
                    </div>
                    <div className="col-75">
                      <select
                        id="premises"
                        name="premises"
                        onChange={handleChange}
                        value={fields["premises"]}
                        required
                      >
                        <option value="Maison">Maison</option>
                        <option value="Appartement">Appartement</option>
                        <option value="Ferme">Ferme</option>
                        <option value="Entrepôt">Entrepôt</option>
                        <option value="Garage">Garage</option>
                        <option value="Autre">Autre</option>
                      </select>
                      {fields["premises"] === "Autre" ? (
                        <>
                          <input
                            type="text"
                            id="accuracy"
                            name="accuracy"
                            placeholder="
                        Entrez le type de local ici..."
                            required
                          />
                        </>
                      ) : null}
                    </div>

                    <div className="col-25">
                      <label htmlFor="area">
                        Superficie <span className="required"></span>
                      </label>
                    </div>
                    <div className="col-75">
                      <input
                        type="text"
                        id="area"
                        name="area"
                        placeholder="
                        Superficie..."
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-25">
                <label htmlFor="message">
                  Message <span className="required">*</span>
                </label>
              </div>
              <div className="col-75">
                <textarea
                  id="message"
                  name="message"
                  placeholder="Écrivez votre message ici..."
                  required
                ></textarea>
              </div>
            </div>
            <br />
            <div className="row">
              <input type="submit" value="Envoyer" />

              <Popup
                open={open}
                closeOnDocumentClick
                onClose={() => {
                  closeModal();
                  clear();
                }}
              >
                <div className="modal">
                  Votre message a bien été envoyé, nous vous recontacterons au
                  plus vite !
                </div>
              </Popup>
            </div>
          </form>
        </div>
      </div>
      <div className="contact-contact">
        <div className="contact">
          <div className="contact-widgets">
            <div>
              <Phone />
            </div>
            <div>
              par téléphone au <a href="tel:06 24 86 62 78">06 24 86 62 78</a>.
            </div>
          </div>
          <div className="contact-widgets">
            <div>
              <Mail />
            </div>
            <div>
              par email à l'adresse{" "}
              <a href="mailto:ent.heldebaume@gmail.com">
                ent.heldebaume@gmail.com
              </a>
              .
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
