import React from "react";
import NavBar from "./header";
import "../styles/housecleaner.css";
import Footer from "./footer";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import { useState } from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { ReactComponent as Phone } from "../assets/images/svg/phone.svg";
import { ReactComponent as Mail } from "../assets/images/svg/mail.svg";
import { ReactComponent as Form } from "../assets/images/svg/form.svg";
import { ReactComponent as Recycling } from "../assets/images/svg/recycling.svg";
import { ReactComponent as Moneybag } from "../assets/images/svg/moneybag.svg";
import { ReactComponent as Time } from "../assets/images/svg/time.svg";
import { ReactComponent as Knowledge } from "../assets/images/svg/knowledge.svg";
import { ReactComponent as Driller } from "../assets/images/svg/driller.svg";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/images/houseCleaner", true, /\.(png|jpe?g|svg)$/)
);

const FIRST_IMAGE1 = {
  imageUrl: images["av3.jpg"],
};
const SECOND_IMAGE1 = {
  imageUrl: images["ap3.jpg"],
};

const FIRST_IMAGE2 = {
  imageUrl: images["av2.jpg"],
};
const SECOND_IMAGE2 = {
  imageUrl: images["ap2.jpg"],
};

const FIRST_IMAGE3 = {
  imageUrl: images["av4.jpg"],
};
const SECOND_IMAGE3 = {
  imageUrl: images["ap4.jpg"],
};

const FIRST_IMAGE4 = {
  imageUrl: images["av5.jpg"],
};
const SECOND_IMAGE4 = {
  imageUrl: images["ap5.jpg"],
};

const FIRST_IMAGE5 = {
  imageUrl: images["av6.jpg"],
};
const SECOND_IMAGE5 = {
  imageUrl: images["ap6.jpg"],
};

const FIRST_IMAGE6 = {
  imageUrl: images["av7.jpg"],
};
const SECOND_IMAGE6 = {
  imageUrl: images["ap7.jpg"],
};

const FIRST_IMAGE7 = {
  imageUrl: images["av8.jpg"],
};
const SECOND_IMAGE7 = {
  imageUrl: images["ap8.jpg"],
};

const FIRST_IMAGE8 = {
  imageUrl: images["av9.jpg"],
};
const SECOND_IMAGE8 = {
  imageUrl: images["ap9.jpg"],
};

const HouseCleaner = () => {
  var [currentStep, setcurrentStep] = useState(1);

  function AddStep() {
    setcurrentStep(currentStep + 1);
    if (currentStep >= 5) {
      setcurrentStep(1);
    }

    if (currentStep === 1) {
      document.getElementById("step2").className = "active";
      document.getElementById("step3").className = "";
      document.getElementById("step4").className = "";
      document.getElementById("step5").className = "";
    }
    if (currentStep === 2) {
      document.getElementById("step3").className = "active";
      document.getElementById("step4").className = "";
      document.getElementById("step5").className = "";
    }
    if (currentStep === 3) {
      document.getElementById("step4").className = "active";
      document.getElementById("step5").className = "";
    }
    if (currentStep === 4) {
      document.getElementById("step5").className = "active";
    }
    if (currentStep === 5) {
      document.getElementById("step2").className = "";
      document.getElementById("step3").className = "";
      document.getElementById("step4").className = "";
      document.getElementById("step5").className = "";
    }
  }

  function RemoveStep() {
    setcurrentStep(currentStep - 1);
    if (currentStep <= 1) {
      setcurrentStep(5);
    }
    if (currentStep === 1) {
      document.getElementById("step2").className = "active";
      document.getElementById("step3").className = "active";
      document.getElementById("step4").className = "active";
      document.getElementById("step5").className = "active";
    }
    if (currentStep === 2) {
      document.getElementById("step2").className = "";
      document.getElementById("step3").className = "";
      document.getElementById("step4").className = "";
      document.getElementById("step5").className = "";
    }
    if (currentStep === 3) {
      document.getElementById("step2").className = "active";
      document.getElementById("step3").className = "";
      document.getElementById("step4").className = "";
      document.getElementById("step5").className = "";
    }
    if (currentStep === 4) {
      document.getElementById("step2").className = "active";
      document.getElementById("step3").className = "active";
      document.getElementById("step4").className = "";
      document.getElementById("step5").className = "";
    }
    if (currentStep === 5) {
      document.getElementById("step2").className = "active";
      document.getElementById("step3").className = "active";
      document.getElementById("step4").className = "active";
      document.getElementById("step5").className = "active";
    }
  }

  console.log(currentStep);

  return (
    <div>
      <NavBar />
      <div className="housecleaner-banner-title">
        <img
          id="banner"
          src={require("../assets/images/header/banner_housecleaner.png")}
          alt="banner_housecleaner"
          className="banner"
        ></img>
        <div className="home-site-title">
          <h1 className="black-title">Ent. Vide Nettoie Tout</h1>
        </div>
      </div>
      <div className="firstblock">
        <h2 className="housecleaner-h2">En quoi consiste notre métier ?</h2>
        <div className="blocks">
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce="true"
          >
            <div className="left-block">
              <div className="left-block-text">
                <p>
                  Nous proposons nos services de <u>vide maison</u> et de
                  récupération des déchets triés.
                </p>
                <p>
                  Débarras et nettoyage <b>complet</b> de maison, appartement,
                  succession, fermettes et tout type de locaux, de la cave au
                  grenier et dépendances.
                </p>
                <p>
                  Nous avons l'habitude de <b>travailler avec des notaires</b>{" "}
                  dans les affaires d'héritage, etc...
                </p>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInRightBig"
            animateOnce="true"
          >
            <div className="right-block">
              <img
                className="illustrations"
                src={images["debarras.jpg"]}
                alt="illustration"
              />
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="housecleaner-secondblock">
        <h2 className="housecleaner-h2">
          Fonctionnement d'un service de vide maison
        </h2>
        <div className="housecleaner-working">
          <div className="progressbar-container">
            <ul className="progressbar">
              <li id="step1" className="active">
                La prise de rendez-vous
              </li>
              <li id="step2" className="">
                Déplacement de l'entreprise
              </li>
              <li id="step3" className="">
                La rédaction du devis gratuit
              </li>
              <li id="step4" className="">
                L'entreprise se met au travail
              </li>
              <li id="step5" className="">
                L'entreprise recycle les déchets
              </li>
            </ul>
          </div>

          <div className="housecleaner-carousel">
            <div className="housecleaner-carousel-buttons">
              <button className="button-afterbefore" onClick={RemoveStep}>
                {"< Étape précédente"}
              </button>
            </div>
            <div>
              {currentStep === 1 ? (
                <>
                  <Step1 />
                </>
              ) : null}
              {currentStep === 2 ? (
                <>
                  <Step2 />
                </>
              ) : null}
              {currentStep === 3 ? (
                <>
                  <Step3 />
                </>
              ) : null}
              {currentStep === 4 ? (
                <>
                  <Step4 />
                </>
              ) : null}
              {currentStep === 5 ? (
                <>
                  <Step5 />
                </>
              ) : null}
            </div>
            <div className="housecleaner-carousel-buttons">
              <button className="button-afterbefore" onClick={AddStep}>
                {"Étape suivante >"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="thirdblock">
        <h2 className="housecleaner-h2">Pourquoi travailler avec nous ?</h2>
        <div>
          <div className="scrapmerchant-cards">
            <div className="scrapmerchant-card">
              <div className="scrapmerchant-card-content">
                <div className="scrapmerchant-card-title">Expérience</div>
                <Knowledge />
                <p className="scrapmerchant-card-description">
                  En colaboration avec les notaires depuis ...
                </p>
              </div>
            </div>
            <div className="scrapmerchant-card">
              <div className="scrapmerchant-card-content">
                <div className="scrapmerchant-card-title">Gratuité</div>
                <Moneybag />
                <p className="scrapmerchant-card-description">
                  Déplacement et devis <u>gratuit</u>.
                </p>
              </div>
            </div>
            <div className="scrapmerchant-card">
              <div className="scrapmerchant-card-content">
                <div className="scrapmerchant-card-title">
                  Respect de l'environnement
                </div>
                <Recycling />
                <p className="scrapmerchant-card-description">
                  Traitement et recyclage des déchets en déchèterie.
                </p>
              </div>
            </div>
            <div className="scrapmerchant-card">
              <div className="scrapmerchant-card-content">
                <div className="scrapmerchant-card-title">Disponibilité</div>
                <Time />
                <p className="scrapmerchant-card-description">
                  Nous intervenons rapidement et efficacement, vous pouvez nous
                  contacter 24h/24 et 7j/7.
                </p>
              </div>
            </div>
            <div className="scrapmerchant-card">
              <div className="scrapmerchant-card-content">
                <div className="scrapmerchant-card-title">Efficacité</div>
                <Driller />
                <p className="scrapmerchant-card-description">
                  Débarras et nettoyage rapide, efficace et complet des maisons,
                  successions, appartements, fermettes, etc...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="housecleaner-prices"></div>
      <div className="housecleaner-gallery">
        <h2 className="housecleaner-h2">Galerie des réalisations</h2>
        <div className="housecleaner-images-beforeafter">
          <div className="housecleaner-oneImage">
            <ReactBeforeSliderComponent
              currentPercentPosition={0.1}
              firstImage={FIRST_IMAGE1}
              secondImage={SECOND_IMAGE1}
            />
          </div>
          <div className="housecleaner-oneImage">
            <ReactBeforeSliderComponent
              currentPercentPosition={0.1}
              firstImage={FIRST_IMAGE2}
              secondImage={SECOND_IMAGE2}
            />
          </div>
          <div className="housecleaner-oneImage">
            <ReactBeforeSliderComponent
              currentPercentPosition={0.1}
              firstImage={FIRST_IMAGE3}
              secondImage={SECOND_IMAGE3}
            />
          </div>
          <div className="housecleaner-oneImage">
            <ReactBeforeSliderComponent
              currentPercentPosition={0.1}
              firstImage={FIRST_IMAGE4}
              secondImage={SECOND_IMAGE4}
            />
          </div>
          <div className="housecleaner-oneImage">
            <ReactBeforeSliderComponent
              currentPercentPosition={0.1}
              firstImage={FIRST_IMAGE5}
              secondImage={SECOND_IMAGE5}
            />
          </div>
          <div className="housecleaner-oneImage">
            <ReactBeforeSliderComponent
              currentPercentPosition={0.1}
              firstImage={FIRST_IMAGE8}
              secondImage={SECOND_IMAGE8}
            />
          </div>
          <div className="housecleaner-oneImage">
            <ReactBeforeSliderComponent
              currentPercentPosition={0.1}
              firstImage={FIRST_IMAGE7}
              secondImage={SECOND_IMAGE7}
            />
          </div>
          <div className="housecleaner-oneImage">
            <ReactBeforeSliderComponent
              currentPercentPosition={0.1}
              firstImage={FIRST_IMAGE6}
              secondImage={SECOND_IMAGE6}
            />
          </div>
        </div>
      </div>
      <div className="fifthblock">
        <div className="housecleaner-contact">
          <h2 className="housecleaner-h2">Nous contacter</h2>
          <div className="contact">
            <div className="contact-widgets">
              <div>
                <Phone />
              </div>
              <div>
                par téléphone au <a href="tel:06 24 86 62 78">06 24 86 62 78</a>
                .
              </div>
            </div>
            <div className="contact-widgets">
              <div>
                <Mail />
              </div>
              <div>
                par email à l'adresse{" "}
                <a href="mailto:ent.heldebaume@gmail.com">
                  ent.heldebaume@gmail.com
                </a>
                .
              </div>
            </div>
            <div className="contact-widgets">
              <div>
                <Form />
              </div>
              <div>
                ou directement depuis le formulaire de contact{" "}
                <a href="/contact">ici</a>.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Step1 = () => {
  return (
    <div className="step">
      <div className="step-number">Étape 1</div>
      <div className="step-title">
        PRISE DE RENDEZ-VOUS AVEC L'ENTREPRISE VIDE NETTOIE TOUT
      </div>
      <div className="step-description">
        Afin de discuter du travail qui est demandé, de savoir où se situe la
        propriété et de fixer une date pour la visite.
      </div>
    </div>
  );
};

const Step2 = () => {
  return (
    <div className="step">
      <div className="step-number">Étape 2</div>
      <div className="step-title">L'ENTREPRISE SE REND SUR LES LIEUX</div>
      <div className="step-description">
        Afin d'estimer l'ampleur du travail et la valeur marchande des
        différents biens de la propriété.
      </div>
    </div>
  );
};

const Step3 = () => {
  return (
    <div className="step">
      <div className="step-number">Étape 3</div>
      <div className="step-title">
        RÉDACTION D'UN DEVIS ENTIÈREMENT GRATUITEMENT
      </div>
      <div className="step-description">
        Après la visite, l'entreprise réalise un devis gratuitement en prenant
        en compte la valeur marchande des biens de la propriété, le temps de
        travail, la main d'œuvre, la necessité ou pas de louer une benne, etc...
      </div>
    </div>
  );
};

const Step4 = () => {
  return (
    <div className="step">
      <div className="step-number">Étape 4</div>
      <div className="step-title">UNE FOIS LE DEVIS ACCEPTÉ</div>
      <div className="step-description">
        L'entreprise se met au travail la date fixée au préalable et vide la
        propriété en fonction du devis.
      </div>
    </div>
  );
};

const Step5 = () => {
  return (
    <div className="step">
      <div className="step-number">Étape 5</div>

      <div className="step-title">RECYCLAGE DES DÉCHETS</div>
      <div className="step-description">
        Nous trions et recyclons tous les objets récupérés.
      </div>
    </div>
  );
};

export default HouseCleaner;
