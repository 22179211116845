import React from "react";
import { Link } from "react-router-dom";
import "../styles/header.css";
import "../styles/responsive.css";

import logo from "../assets/images/header/logo.png";

const NavBar = () => {
  var onlineUrl = "https://ent-heldebaume.fr/";

  return (
    <header>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
        integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
        crossOrigin=""
      />
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin=""
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Anton&family=Open+Sans:wght@300&display=swap"
        rel="stylesheet"
      ></link>
      <ul className="topnav" id="navbarcolor">
        <div className="navbar">
          <div className="navbar-buttons">
            <div className="navbar-3buttons">
              <li>
                <Link
                  id="home"
                  className={window.location.href === onlineUrl ? "active" : ""}
                  to="/"
                >
                  Accueil
                </Link>
              </li>
              <li>
                <Link
                  id="pruner"
                  className={
                    window.location.href === onlineUrl + "elagage"
                      ? "active"
                      : ""
                  }
                  to="/elagage"
                >
                  Élagage
                </Link>
              </li>
              <li>
                <Link
                  id="scrapmerchant"
                  className={
                    window.location.href === onlineUrl + "ferraille"
                      ? "active"
                      : ""
                  }
                  to="/ferraille"
                >
                  Ferraille
                </Link>
              </li>
            </div>
            <div className="top-illu">
              <div className="top-illu-left">
                <Link to="/">
                  <img alt="" src={logo} width="350px"></img>
                </Link>
              </div>
            </div>
            <div className="navbar-3buttons">
              <li>
                <Link
                  id="housecleaner"
                  className={
                    window.location.href === onlineUrl + "videmaison"
                      ? "active"
                      : ""
                  }
                  to="/videmaison"
                >
                  Vide-maison
                </Link>
              </li>
              <li>
                <Link
                  id="clearingservice"
                  className={
                    window.location.href === onlineUrl + "debarras"
                      ? "active"
                      : ""
                  }
                  to="/debarras"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  id="contact"
                  className={
                    window.location.href === onlineUrl + "contact"
                      ? "active"
                      : ""
                  }
                  to="/contact"
                >
                  Nous contacter
                </Link>
              </li>
            </div>
          </div>
        </div>
      </ul>
    </header>
  );
};

export default NavBar;
