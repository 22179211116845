import NavBar from "./header";
import { ReactComponent as Phone } from "../assets/images/svg/phone.svg";
import { ReactComponent as Mail } from "../assets/images/svg/mail.svg";
import { ReactComponent as Form } from "../assets/images/svg/form.svg";
import { ReactComponent as Recycling } from "../assets/images/svg/recycling.svg";

import { ReactComponent as Time } from "../assets/images/svg/time.svg";

import { AnimationOnScroll } from "react-animation-on-scroll";
import Footer from "./footer";
import "../styles/clearingservice.css";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context(
    "../assets/images/clearingService",
    true,
    /\.(png|jpe?g|svg)$/
  )
);

const ClearingService = () => {
  return (
    <div>
      <NavBar />
      <div className="clearingservice-banner-title">
        <img
          id="banner"
          src={require("../assets/images/header/banner_clearingservice.png")}
          alt="banner_clearingservice"
          className="banner"
        ></img>
        <div className="clearingservice-site-title">
          <h1 id="title">Services en tout genre</h1>
        </div>
      </div>
      <div className="firstblock">
        <h2 className="clearingservice-h2">
          Un devis gratuit, pour n'importe quel service.
        </h2>
        <div className="blocks">
          <AnimationOnScroll
            animateIn="animate__fadeInLeftBig"
            animateOnce="true"
          >
            <div className="left-block">
              <div className="left-block-text">
                <p>
                  L'entreprise Heldebaume François propose également de vous
                  débarrasser de vos <b>encombrants</b>, votre vieux <b>bois</b>
                  , et <b>tout déchet</b> destiné à la déchetterie.
                </p>
                <p>
                  Nous pouvons également réaliser des déménagements ou autre, si
                  vous souhaitez en savoir plus n'hésitez surtout pas à{" "}
                  <b>nous appeler</b> !
                </p>
                <p>
                  Ce service se fait sous devis que nous établissons{" "}
                  <u>gratuitement</u>
                </p>
                <p>
                  Le coût de ce service dépend du travail demandé, des dépenses{" "}
                  <b>potentielles</b> liées à l'enlèvement des déchets (Location
                  de bennes, dépôt en déchetterie, etc...).
                </p>
              </div>
            </div>
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeInRightBig"
            animateOnce="true"
          >
            <div className="right-block">
              <img
                className="illustrations"
                src={images["tout.jpg"]}
                alt="illustration"
              />
            </div>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="secondblock">
        <h2 className="clearingservice-h2">Nos points forts :</h2>
        <div className="clearingservice-cards">
          <div className="clearingservice-card">
            <div className="clearingservice-card-content">
              <div className="clearingservice-card-title">
                Respect de l'environnement
              </div>
              <Recycling />
              <p className="clearingservice-card-description">
                Traitement et recyclage des déchets en centre professionnel.
              </p>
            </div>
          </div>
          <div className="clearingservice-card">
            <div className="clearingservice-card-content">
              <div className="clearingservice-card-title">Disponibilité</div>
              <Time />
              <p className="clearingservice-card-description">
                Nous intervenons rapidement et efficacement, vous pouvez nous
                contacter 24h/24 et 7j/7.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="clearingservice-contact">
        <h2 className="clearingservice-h2">Nous contacter</h2>
        <div className="contact">
          <div className="contact-widgets">
            <div>
              <Phone />
            </div>
            <div>
              par téléphone au <a href="tel:06 24 86 62 78">06 24 86 62 78</a>.
            </div>
          </div>
          <div className="contact-widgets">
            <div>
              <Mail />
            </div>
            <div>
              par email à l'adresse{" "}
              <a href="mailto:ent.heldebaume@gmail.com">
                ent.heldebaume@gmail.com
              </a>
              .
            </div>
          </div>
          <div className="contact-widgets">
            <div>
              <Form />
            </div>
            <div>
              ou directement depuis le formulaire de contact{" "}
              <a href="/contact">ici</a>.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ClearingService;
