import React, { Component } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import NavBar from "./header";
import Footer from "./footer";
import "../styles/home.css";
import "../styles/global.css";
import "animate.css/animate.min.css";
import { Link } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  CircleMarker,
  Popup,
  Marker,
} from "react-leaflet";
import L from "leaflet";
import { ReactComponent as Phone } from "../assets/images/svg/phone.svg";
import { ReactComponent as Mail } from "../assets/images/svg/mail.svg";
import { ReactComponent as Form } from "../assets/images/svg/form.svg";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

/* function RemoveCookieBanner() {
  document.getElementsByClassName("cookie-consent-banner")[0].style.visibility =
    "hidden";
} */

export default class Home extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <div className="home-banner-title">
          <img
            id="banner"
            src={require("../assets/images/header/banner_home.png")}
            alt="banner_home"
            className="banner"
          ></img>
          <div className="home-site-title">
            <h1 className="blank-title">Ent. Heldebaume François</h1>
          </div>
        </div>
        <div className="informations">
          <h2 className="home-h2">Informations</h2>
          <div className="blocks">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce="true"
            >
              <div className="left-block">
                <div className="left-block-text">
                  <p>
                    En service depuis <b>2002</b>, l' entreprise{" "}
                    <b>Heldebaume François </b> propose la réalisation de
                    différentes tâches pour les professionnels comme les
                    particuliers.
                  </p>
                  <p>
                    <b>Le débarras de toute ferraille</b>, métaux et aussi
                    l'enlèvement des épaves de voiture.{" "}
                    <b>
                      Le débarras et nettoyage complet de maison, appartement,
                      succession, fermettes, etc...
                    </b>{" "}
                    de la cave au grenier, sans oublier l'extérieur !{" "}
                    <b>L'entretien des espaces verts</b>, par le biais de la
                    tonte, la taille de haie, le débroussaillage mais aussi
                    l'élagage et l'abbatage de tout type d'arbre. Et enfin, les
                    services en tout genre, c'est simple, vous avez quelque
                    chose à débarasser, l'entreprise se déplace,{" "}
                    <b>
                      estime le travail à fournir et établi un devis
                      gratuitement
                    </b>
                    .
                  </p>
                </div>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce="true"
            >
              <div className="right-block">
                <img
                  className="illustrations"
                  src={require("../assets/images/home/illu1.jpg")}
                  alt="illustration"
                />
              </div>
            </AnimationOnScroll>
          </div>
        </div>

        <div className="services">
          <div className="services-title">
            <h2 className="home-h2">Nos services</h2>
          </div>
          <AnimationOnScroll animateIn="animate__bounceInUp" animateOnce="true">
            <div className="cards">
              <div className="card">
                <div className="card-header" id="card-header-1">
                  <p>Élagage</p>
                </div>
                <div className="card-body">
                  <p className="card-description">
                    Je propose mes services d’entretient des espaces verts, tel
                    que l’élagage, l’abbatage, la taille des haies, la tonte des
                    pelouses, le débroussaillage, etc...
                  </p>
                  <div className="card-list">
                    <ul>
                      <li>
                        Devis gratuit <span className="check">✓</span>
                      </li>
                      <li>
                        Déplacement gratuit <span className="check">✓</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/elagage">
                    <button className="know-more" id="know-more-1">
                      <span>En savoir plus</span>
                    </button>
                  </Link>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="card-header-2">
                  <p>Débarras de ferraille</p>
                </div>
                <div className="card-body">
                  <p className="card-description">
                    Je débarasse, démonte, découpe tout type de ferraille et
                    métaux, pour les particuliers comme les professionnels.
                  </p>
                  <div className="card-list">
                    <ul>
                      <li>
                        Devis gratuit <span className="check">✓</span>
                      </li>
                      <li>
                        Déplacement gratuit
                        <span className="check">✓</span>
                      </li>
                      <li>
                        Débarras de voitures <span className="check">✓</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/ferraille">
                    <button className="know-more" id="know-more-2">
                      <span>En savoir plus</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="card-header-3">
                  <p>Vide-maison</p>
                </div>
                <div className="card-body">
                  <p className="card-description">
                    Je propose mes services de débarras et nettoyage de maison,
                    succession, appartement, etc... <br></br>Notre entreprise
                    est habituée à travailler avec les notaires.
                  </p>
                  <div className="card-list">
                    <ul>
                      <li>
                        Devis gratuit <span className="check">✓</span>
                      </li>
                      <li>
                        Déplacement gratuit <span className="check">✓</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/videmaison">
                    <button className="know-more" id="know-more-3">
                      <span>En savoir plus</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="card-header-4">
                  <p>Services en tout genre</p>
                </div>
                <div className="card-body">
                  <p className="card-description">
                    Je propose aussi, des services en tout genre, comme le
                    débarras d'encombrants, les déménagements, etc...
                  </p>
                  <div className="card-list">
                    <ul>
                      <li>
                        Devis gratuit <span className="check">✓</span>
                      </li>
                      <li>
                        Déplacement gratuit <span className="check">✓</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-footer">
                  <Link to="/debarras">
                    <button className="know-more" id="know-more-4">
                      <span>En savoir plus</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </div>

        <div className="availability">
          <h2 className="home-h2">Mes disponibilités</h2>
          <div className="blocks">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce="true"
            >
              <div className="left-block">
                <img
                  className="illustrations"
                  src={require("../assets/images/home/time.jpg")}
                  alt="illustrations"
                />
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce="true"
            >
              <div className="right-block">
                <div className="right-block-text">
                  <p>
                    Nous travaillons tout le long de l'année de le secteur des
                    <b> Hauts-De-France</b>.
                  </p>
                  <p>
                    Nous pouvons aussi être amenés à nous déplacer plus loin en
                    fonction de la taille du chantier.
                  </p>
                  <p>
                    {" "}
                    Vous pouvez nous contacter par téléphone, par mail ou encore
                    avec le formulaire de contact <b>24h/24 et 7j/7</b>
                  </p>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </div>

        <div className="home-contact">
          <h2 className="home-h2">Nous contacter</h2>
          <div className="contact-blocks">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce="true"
            >
              <div className="left-block">
                <b>
                  <h3>Vous pouvez nous contacter :</h3>
                </b>
                <div className="contact">
                  <div className="contact-widgets">
                    <div>
                      <Phone />
                    </div>
                    <div>
                      par téléphone au{" "}
                      <a href="tel:06 24 86 62 78">06 24 86 62 78</a>.
                    </div>
                  </div>
                  <div className="contact-widgets">
                    <div>
                      <Mail />
                    </div>
                    <div>
                      par email à l'adresse{" "}
                      <a href="mailto:ent.heldebaume@gmail.com">
                        ent.heldebaume@gmail.com
                      </a>
                      .
                    </div>
                  </div>
                  <div className="contact-widgets">
                    <div>
                      <Form />
                    </div>
                    <div>
                      ou directement depuis le formulaire de contact{" "}
                      <a href="/contact">ici</a>.
                    </div>
                  </div>
                </div>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce="true"
            >
              <div className="right-block-contact">
                <MapContainer
                  center={[50.811614202611786, 2.198042013532687]}
                  zoom={9}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <CircleMarker
                    center={[50.811614202611786, 2.198042013532687]}
                    radius={240}
                  ></CircleMarker>
                  <Marker position={[50.811614202611786, 2.198042013532687]}>
                    <Popup>
                      Entreprise Vide Nettoie Tout <br /> 5 rue d'hellebroucq à
                      Houlle
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </AnimationOnScroll>
          </div>
        </div>

        {/*  <div className="cookie-consent-banner">
          <div className="cookie-consent-banner__inner">
            <div className="cookie-consent-banner__copy">
              <div className="cookie-consent-banner__header">
                CE SITE WEB UTILISE LES COOKIES
              </div>
              <div className="cookie-consent-banner__description">
                <label className="switch">
                  <input type="checkbox" />

                  <div className="slider round"></div>
                </label>
              </div>
            </div>

            {/* <div className="cookie-consent-banner__actions">
              <button
                className="cookie-consent-banner__cta"
                onClick={RemoveCookieBanner}
              >
                OK
              </button>

              <button className="cookie-consent-banner__cta cookie-consent-banner__cta--secondary">
                Decline
              </button>
            </div> 
          </div>
        </div> */}
        <Footer />
      </div>
    );
  }
}
