import React from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-bar">
        <div className="home">
          <Link to="/">
            <button className="footer-button">Accueil</button>
          </Link>
        </div>
        <div className="legal-notice">
          <Link to="/mentions-legales">
            <button className="footer-button">Mentions légales</button>
          </Link>
        </div>
        <div className="legal-notice">
          <Link to="/privacy-policy">
            <button className="footer-button">
              Politique de confidentialité
            </button>
          </Link>
        </div>
        <div className="contact-us">
          <Link to="/contact">
            <button className="footer-button">Nous contacter</button>
          </Link>
        </div>

        <div className="credits">
          <p>Site web réalisé par l'entreprise Heldebaume</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
