import React from "react";
import NavBar from "./header";
import Footer from "./footer";

const LegalNotice = () => {
  return (
    <div>
      <NavBar />
      <div className="privacy-policy">
        <h3>1 - Édition du site</h3>
        <p>
          En vertu de{" "}
          <a href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000801164/#LEGIARTI000042038977">
            l'article 6 de la loi n° 2004-575 du 21 juin 2004
          </a>{" "}
          pour la confiance dans l'économie numérique, il est précisé aux
          utilisateurs du site internet https://ent-heldebaume.fr/ l'identité
          des différents intervenants dans le cadre de sa réalisation et de son
          suivi:
          <p>
            <b>Propriétaire du site :</b> Entrepreneur individuel : Heldebaume
            François - Contact :{" "}
            <a href="mailto:ent.heldebaume@gmail.com">
              ent.heldebaume@gmail.com
            </a>{" "}
            - Adresse : 5 rue d'hellebroucq 62910 Houlle.
          </p>
          <p>
            <b>Identification de l'entreprise :</b> EI Entrepreneur individuel :
            Heldebaume François - RCS : 443 555 602 - Adresse postale : 5 rue
            d'hellebroucq 62910 Houlle
          </p>
          <p>
            <b>Hébergeur :</b> Firebase Hosting (Google) Google LLC, 1600
            Amphitheatre Parkway, Mountain View, California 94043 USA
          </p>
        </p>
        <h3>2 - Propriété intellectuelle et contrefaçons.</h3>
        <p>
          L'entrepreneur individuel : <b>Heldebaume François</b> est
          propriétaire des droits de propriété intellectuelle et détient les
          droits d’usage sur tous les éléments accessibles sur le site internet,
          notamment les textes, images, graphismes, logos, vidéos, architecture,
          icônes et sons. Toute reproduction, représentation, modification,
          publication, adaptation de tout ou partie des éléments du site, quel
          que soit le moyen ou le procédé utilisé, est interdite, sauf
          autorisation écrite préalable de <b>Heldebaume François</b>. Toute
          exploitation non autorisée du site ou de l’un quelconque des éléments
          qu’il contient sera considérée comme constitutive d’une contrefaçon et
          poursuivie conformément aux dispositions des articles{" "}
          <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032655082/">
            L.335-2 et suivants du Code de Propriété Intellectuelle
          </a>
          .
        </p>
        <h3>3 - Limitations de responsabilité.</h3>
        <p>
          L'Entrepreneur individuel : <b>Heldebaume François</b> s’engage à
          sécuriser au mieux le site{" "}
          <a href="https://ent-heldebaume.fr/">https://ent-heldebaume.fr/</a>,
          cependant sa responsabilité ne pourra être mise en cause si des
          données indésirables sont importées et installées sur son site à son
          insu.
          {/* Des espaces interactifs (espace contact ou commentaires) sont à
          la disposition des utilisateurs. Entrepreneur individuel : Heldebaume
          François se réserve le droit de supprimer, sans mise en demeure
          préalable, tout contenu déposé dans cet espace qui contreviendrait à
          la législation applicable en France, en particulier aux dispositions
          relatives à la protection des données. Le cas échéant, Entrepreneur
          individuel : Heldebaume François se réserve également la possibilité
          de mettre en cause la responsabilité civile et/ou pénale de
          l’utilisateur, notamment en cas de message à caractère raciste,
          injurieux, diffamant, ou pornographique, quel que soit le support
          utilisé (texte, photographie …). */}
        </p>
        <h3>4 - CNIL et gestion des données personnelles.</h3>
        <p>
          Conformément aux dispositions de{" "}
          <a href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000886460/">
            la loi 78-17 du 6 janvier 1978 modifiée
          </a>
          , l’utilisateur du site{" "}
          <a href="https://ent-heldebaume.fr/">https://ent-heldebaume.fr/</a>{" "}
          dispose d’un droit d’accès, de modification et de suppression des
          informations collectées.
          <p></p>Pour exercer ce droit, envoyez un message à cette adresse mail
          :{" "}
          <a href="mailto:ent.heldebaume@gmail.com">ent.heldebaume@gmail.com</a>
          <p>
            Pour plus d'informations sur la façon dont nous traitons vos données
            (type de données, finalité, destinataire...), lisez notre politique
            de confidentialité :{" "}
            <a href="https://ent-heldebaume.fr/privacy-policy">
              https://ent-heldebaume.fr/privacy-policy
            </a>
            .
          </p>
        </p>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default LegalNotice;
