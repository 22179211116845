import React from "react";
import NavBar from "./header";
import "react-before-after-slider-component/dist/build.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../styles/pruner.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import Footer from "./footer";
import { useState } from "react";
import { ReactComponent as Phone } from "../assets/images/svg/phone.svg";
import { ReactComponent as Mail } from "../assets/images/svg/mail.svg";
import { ReactComponent as Form } from "../assets/images/svg/form.svg";

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("../assets/images/pruner", true, /\.(png|jpe?g|svg)$/)
);

const FIRST_IMAGE1 = {
  imageUrl: images["av1.jpeg"],
};
const SECOND_IMAGE1 = {
  imageUrl: images["ap1.jpeg"],
};

const FIRST_IMAGE2 = {
  imageUrl: images["av2.jpeg"],
};
const SECOND_IMAGE2 = {
  imageUrl: images["ap2.jpeg"],
};

const Pruner = () => {
  var [currentStep, setcurrentStep] = useState(1);

  function AddStep() {
    setcurrentStep(currentStep + 1);
    if (currentStep >= 9) {
      setcurrentStep(1);
    }
  }

  function RemoveStep() {
    setcurrentStep(currentStep - 1);
    if (currentStep <= 1) {
      setcurrentStep(9);
    }
  }

  return (
    <div>
      <header>
        <NavBar />
        <div className="pruner-banner-title">
          <img
            id="banner"
            src={require("../assets/images/header/banner_pruner.png")}
            alt="banner_pruner"
            className="banner"
          ></img>
          <div className="pruner-site-title">
            <h1 id="title">Entretien et élagage des espaces verts</h1>
          </div>
        </div>
      </header>

      <div>
        <div className="informations">
          <h2 className="pruner-h2">Informations</h2>
          <div className="blocks">
            <AnimationOnScroll
              animateIn="animate__fadeInLeftBig"
              animateOnce="true"
            >
              <div className="left-block">
                <div className="left-block-text">
                  <p>
                    Un arbre à abattre ? Une pelouse à tondre ? Une haie à
                    tailler ?
                  </p>
                  <p>
                    Du grand chantier d'entretien des espaces verts à la petite
                    intervention de nettoyage, nous sommes disponibles pour
                    intervenir efficacement et proprement dans votre jardin !
                  </p>
                  <p>
                    Nous intervenons dans les <b>Hauts-De-France</b> pour tout
                    type de travaux d'entretien des espaces verts, tel que,
                    l'élagage et l'abattage d'arbres, la tonte de pelouse, la
                    taille des haies, le débroussaillage, etc...
                  </p>
                  <p></p>
                </div>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              animateIn="animate__fadeInRightBig"
              animateOnce="true"
            >
              <div className="right-block">
                <img
                  className="illustrations"
                  src={images["tronc.jpg"]}
                  alt="ferraille"
                />
              </div>
            </AnimationOnScroll>
          </div>
        </div>
        <div className="pruner-steps">
          <h2 className="pruner-h2">Les étapes d'un abattage :</h2>
          <div className="pruner-carousel-description">
            <div className="pruner-description">
              <div className="right-block-text">
                {currentStep === 1 ? (
                  <>
                    <h3>Première étape : l'observation</h3>

                    <p>
                      Nos grimpeurs observent l'arbre afin de décider de la
                      meilleure méthode pour abattre ce dernier.
                    </p>
                    <p>
                      La plus part du temps, il faudra commencer par monter
                      jusqu'à son sommet en enlevant de la masse au fur et à
                      mesure.
                    </p>
                  </>
                ) : null}
                {currentStep === 2 ? (
                  <>
                    <h3>Seconde étape : l'élagage</h3>

                    <p>
                      Nos grimpeurs s'attachent à l'arbre et commencent à lui
                      retirer de la masse en coupant les branches.
                    </p>
                    <p>
                      Nos grimpeurs sont <b>équipés</b> et <b>habitués</b> à
                      cette pratique, ils font ça en toute sécurité et très
                      proprement.
                    </p>
                  </>
                ) : null}
                {currentStep === 3 ? (
                  <>
                    <h3>Seconde étape : l'élagage</h3>

                    <p>Et ce, jusqu'à atteindre la tête de l'arbre.</p>
                  </>
                ) : null}
                {currentStep === 4 ? (
                  <>
                    <h3>Troisième étape : l'étêtement</h3>{" "}
                    <p>
                      Maintenent que l'arbre est bien élagué, il peut être
                      abattu, nos grimpeurs abattent l'abre en toute sécurité,
                      petit à petit en commençant par la tête de ce dernier.
                    </p>
                  </>
                ) : null}
                {currentStep === 5 ? (
                  <>
                    <h3>Quatrième étape : l'abattage</h3>{" "}
                    <p>
                      Comme dit précédement, nos grimpeurs coupent petit à petit
                      en bûches, l'arbre jusqu'à ce qu'il soit tombable.
                    </p>
                  </>
                ) : null}
                {currentStep === 6 ? (
                  <>
                    <h3>Cinquième étape : l'abattage dirigé</h3>{" "}
                    <p>
                      On attache le reste de l'arbre à une corde afin de diriger
                      sa chute en toute sécurité.
                    </p>
                  </>
                ) : null}
                {currentStep === 7 ? (
                  <>
                    <h3>Cinquième étape : l'abattage dirigé</h3>{" "}
                    <p>
                      Ensuite on vient tirer à plusieurs sur la corde tandis que
                      l'un de nos élagueurs coupe l'arbre à la base.
                    </p>
                  </>
                ) : null}
                {currentStep === 8 ? (
                  <>
                    <h3>Sixième étape : le débitage</h3>

                    <p>
                      Une fois l'abre au sol, on coupe ce dernier en plusieurs
                      morceaux du bûches afin de vous en débarasser plus
                      efficacement, ous si vous souhaitez le garder, pour que ça
                      soit plus pratique pour vous.
                    </p>
                  </>
                ) : null}
                {currentStep === 9 ? (
                  <>
                    <h3>Sixième étape : le débitage</h3>

                    <p>Et enfin, le résultat de l'abattage.</p>
                  </>
                ) : null}
              </div>
            </div>
            <div className="pruner-carousel">
              <div id="arrow_1" className="arrow-wrapper">
                <div className="arrow arrow--left" onClick={RemoveStep}>
                  <span></span>
                </div>
                <div>
                  {currentStep === 1 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img1.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}

                  {currentStep === 2 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img2.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}
                  {currentStep === 3 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img3.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}
                  {currentStep === 4 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img4.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}
                  {currentStep === 5 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img5.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}
                  {currentStep === 6 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img6.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}
                  {currentStep === 7 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img7.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}
                  {currentStep === 8 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img8.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}
                  {currentStep === 9 ? (
                    <>
                      <img
                        className="pruner-carousel-img"
                        src={images["img9.jpg"]}
                        alt=""
                      />
                    </>
                  ) : null}
                </div>
                <div className="arrow arrow--right" onClick={AddStep}>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pruner-gallery">
          <h2 className="pruner-h2">Galerie des réalisations</h2>
          <div className="pruner-images-beforeafter">
            <div className="pruner-oneImage">
              <ReactBeforeSliderComponent
                currentPercentPosition={0.1}
                firstImage={FIRST_IMAGE1}
                secondImage={SECOND_IMAGE1}
              />
            </div>
            <div className="pruner-oneImage">
              <ReactBeforeSliderComponent
                currentPercentPosition={0.1}
                firstImage={FIRST_IMAGE2}
                secondImage={SECOND_IMAGE2}
              />
            </div>
          </div>
          <div className="gallery">
            <div className="gallery__column">
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (9).jpg"]}
                    alt="Abattage d'un sapin d'environ 10 mètres"
                    className="gallery__image"
                  ></img>
                  <figcaption className="gallery__caption">
                    Abattage d'un sapin d'environ 10 mètres.
                  </figcaption>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (16).jpg"]}
                    alt="Utilisation d'une corde pour la rétention de branche"
                    className="gallery__image"
                  ></img>
                  <figcaption className="gallery__caption">
                    Utilisation d'une corde pour la rétention de branche.
                  </figcaption>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (21).jpg"]}
                    alt="Utilisation d'une broyeuse thérmique pour les branches"
                    className="gallery__image"
                  ></img>
                  <figcaption className="gallery__caption">
                    Utilisation d'une broyeuse thérmique pour les branches.
                  </figcaption>
                </figure>
              </span>
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (38).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (40).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (44).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>
            </div>

            <div className="gallery__column">
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["haie.jpg"]}
                    alt="Taille d'une haie large"
                    className="gallery__image"
                  ></img>
                  <figcaption className="gallery__caption">
                    Taille d'une haie large.
                  </figcaption>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (28).jpg"]}
                    alt="Élagage de trois sapins"
                    className="gallery__image"
                  ></img>
                  <figcaption className="gallery__caption">
                    Élagage de trois sapins.
                  </figcaption>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (29).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (37).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (41).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>
            </div>

            <div className="gallery__column">
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (30).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (31).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (32).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (36).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (39).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>
            </div>

            <div className="gallery__column">
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (33).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (34).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>

              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (35).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>
              <span target="_blank" className="gallery__link">
                <figure className="gallery__thumb">
                  <img
                    src={images["e (42).jpeg"]}
                    alt="empty"
                    className="gallery__image"
                  ></img>
                </figure>
              </span>
            </div>
          </div>
        </div>
        <div className="pruner-contact">
          <h2 className="pruner-h2">Nous contacter</h2>

          <div className="contact">
            <div className="contact-widgets">
              <div>
                <Phone />
              </div>
              <div>
                Par téléphone au <a href="tel:06 24 86 62 78">06 24 86 62 78</a>
                .
              </div>
            </div>
            <div className="contact-widgets">
              <div>
                <Mail />
              </div>
              <div>
                Par email à l'adresse suivante{" "}
                <a href="mailto:ent.heldebaume@gmail.com">
                  ent.heldebaume@gmail.com
                </a>
                .
              </div>
            </div>
            <div className="contact-widgets">
              <div>
                <Form />
              </div>
              <div>
                Ou directement depuis le formulaire de contact{" "}
                <a href="/contact">ici</a>.
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Pruner;
